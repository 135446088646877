import Vue from 'vue';
import VueCookies from 'vue-cookies';
import PubSubUtil from '@/pubsub';
import { datadogRum } from '@datadog/browser-rum';

// set default cookies to be 7d, root, *.helixintel.com and secure for https only
Vue.use(VueCookies);
Vue.$cookies.config('7d', '/', import.meta.env.VITE_COOKIE_SITE, true);

const isMobile = () => window.outerWidth < 992;

const cookieName = import.meta.env.VITE_COOKIE_NAME || 'dev-helix';
const flagsRefreshTime = 300e3;
const gitHashRefreshTime = 60e3;

const getKey = (key, ModelType) => {
  const session = Vue.$cookies.get(cookieName) || {};

  if (ModelType) {
    return session[key] ? new ModelType(session[key]) : null;
  }

  return session[key] || null;
};

const clearAll = (except = []) => {
  const session = Vue.$cookies.get(cookieName) || {};
  Object.keys(session).forEach((key) => {
    if (except.indexOf(key) === -1) {
      delete session[key];
    }
  });
  Vue.$cookies.set(cookieName, session);
};

const defaultTooltip = content => ({
  content,
  placement: 'bottom',
  hideOnTargetClick: true,
  delay: { show: 300, hide: 0 },
});

const globals = {
  data: () => ({
    pubSubUtil: new PubSubUtil(),
    lastRoute: null,
    AppLoader: true,
    openedForMobile: false,
    routeRequiresAuth: false,

    defaultDisplayValue: '—',

    globalSettings: {
      show_self_signup: true,
      require_confirm_email: true,
    },

    userSettingsCache: {},

    // left side bar
    // start with bar open unless mobile size
    SideBarOpen: true,
    BlockOpenSidebar: false,
    SideBarReopen: false,
    MobileMenuOpen: false,
    hoverOnSidebar: false,

    AccountMenuOpen: false,
    justToggledNav: false,

    CreateMenuOpen: false,

    unreadNotifications: 0,
    workRequestSidebarCount: null,
    reservationRequestSidebarCount: null,

    userClient: null, // bound in the app.vue on app launch
    notificationClient: null,
    companyClient: null,
    adminClient: null,
    affiliateClient: null,
    marketplaceClient: null,
    infoClient: null,
    weatherClient: null,
    suggestionClient: null,
    workOrderRequestClient: null,
    pmScheduleClient: null,
    reservationClient: null,

    stats: null,

    gitHashTimeout: null,
    gitHash: null,
    hasNewVersion: false,

    focusTimeoutMs: 30e3, // 30 seconds
    notificationsTimeout: null,

    sessionWarningTimeout: null,
    sessionExpiredTimeout: null,
    sessionModal: null,
    urlAffiliate: null,
    checkedForUrlAffiliate: false,

    // filter options for affiliate
    affiliateDashboardFilters: null,
    selectedAffiliateFilters: [],
    selectedAffiliateCompanyFilters: [],
    affiliateFiltersChanged: null,

    // from globals / authorization vuex
    showSiteLoader: true,

    // make sure shadow props are not set until after getRemoteUser happens in app.vue
    currentUser: null,
    shadowUser: null,
    shadowCompany: null,

    // note that these getKey props need to define a model type or itll just be a generic model
    companyId: getKey('companyId') || 0,
    shadowStack: getKey('shadowStack') || [],
    shadowAffiliate: false, // if from owner affiliate table
    selectedAffiliate: null,
    selectedAffiliateColors: null,
    currentRoute: getKey('currentRoute') || '',
    selectedDashboard: getKey('selectedDashboard') || 'eo', // eo|sp|owner
    accessToken: getKey('accessToken') || '',
    refreshToken: getKey('refreshToken') || '',
    JUST_JOINED_COMPANY: getKey('JUST_JOINED_COMPANY') || 0,

    globalSuccessAlertText: '',
    globalErrorAlertText: '',
    globalAlertDelay: null,

    noFilteredResultsMsg: 'No exact matches found. Please try filtering or searching by different criteria.',

    customEvents: {
      equipmentSaved: 'equipmentSaved',
      locationSaved: 'locationSaved',
      taskSaved: 'taskSaved',
      invitedUser: 'invitedUser',
      partSaved: 'partSaved',
      rfpSaved: 'rfpSaved',
      taskCompleted: 'taskCompleted',
      vendorAdded: 'vendorAdded',
      customerAdded: 'customerAdded',
      invoicePaid: 'invoicePaid',
    },

    lightboxIndex: null,
    lightboxImages: [],

    cachedData: null, // set in app.vue

    refreshFlagTimeout: null,
    userFeatureFlags: {},

    refreshCapabilitiesTimeout: null,
    companyCapabilities: {},

    topAlertText: null,
    topAlertCtaText: null,
    topAlertCtaCallback: null,
    profileCompanyModel: null,
    hasChanges: null,

    updateEverySecondInterval: null,
    updateEverySecondComponents: [],

    mobileAppDeviceId: '',
    mobileKeyboardOpen: null,
    mobileKeyboardHeight: 0,
  }),
  computed: {
    currentShadowInfo() {
      if (!this.shadowStack?.length) return null;
      return this.shadowStack[this.shadowStack.length - 1];
    },
    blockAppCues() {
      // user needs to accept terms or company needs to verify data
      return this.currentUser?.require_terms_acceptance || (!this.ownerInShadowMode() && this.company()?.requires_affiliate_data_verification);
    },
  },
  methods: {
    defaultTooltip,
    conditionalTooltip: (show, str) => (show ? defaultTooltip(str) : null),
    addUpdateEverySecondComponent(component) {
      if (this.updateEverySecondComponents.find(c => c === component)) return;

      if (!component.updateEverySecond) {
        console.error('component missing updateEverySecond method');
        return;
      }

      this.updateEverySecondComponents.push(component);

      if (this.updateEverySecondInterval === null) {
        this.updateEverySecondInterval = setInterval(this.runEverySecond, 1000);
      }
    },
    removeUpdateEverySecondComponent(component) {
      this.updateEverySecondComponents = this.updateEverySecondComponents.filter(f => f !== component);
    },
    runEverySecond() {
      this.updateEverySecondComponents = this.updateEverySecondComponents.filter(c => !c._isDestroyed && c.updateEverySecond);

      if (!this.updateEverySecondComponents.length) {
        clearInterval(this.updateEverySecondInterval);
        this.updateEverySecondInterval = null;
        return;
      }
      this.updateEverySecondComponents.forEach((component) => {
        component.updateEverySecond();
      });
    },
    // useful globals
    user() {
      if (this.shadowUser) {
        return this.shadowUser;
      }

      return this.currentUser || null;
    },
    company() {
      if (this.shadowCompany && this.shadowCompany.id === this.companyId) {
        return this.shadowCompany;
      }

      // shadowing a user with no companies, like an affiliate manager
      if (this.shadowUser) {
        return this.shadowUser.currentCompany || null;
      }

      if (this.currentUser) {
        return this.currentUser.currentCompany || null;
      }

      return null;
    },
    removeTracking() {
      datadogRum.stopSession();
      datadogRum.clearUser();
      window.AppCues?.reset();
    },
    trackPage() {
      // if we need to show the terms and conditions modal
      // block app cues from firing
      if (this.blockAppCues) {
        return;
      }
      window.Appcues?.page();
    },
    activateTracking() {
      // use current user, not user(), so that shadow login
      // will not fire events for the shadowed user
      const user = this.currentUser;
      if (this.blockAppCues || !user) {
        this.removeTracking();
        return;
      }

      const currentCompany = user.currentCompany;
      const data = {
        createdAt: user.date_joined ? user.date_joined.getTime() : 0,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        userType: (currentCompany && currentCompany.company_role) || '',
        permissions: (currentCompany && currentCompany.company_permissions.join(',')) || '',
        companyName: (currentCompany && currentCompany.name) || '',
        selectedDashboard: this.selectedDashboard.toUpperCase(),
        companyNAIC: (currentCompany && currentCompany.naic_code) || '',
        companyAffiliate: (currentCompany && currentCompany.affiliate && currentCompany.affiliate.name) || '',
      };

      if (window.Appcues) {
        window.Appcues.identify(user.id, data);
      }

      if (Object.keys(datadogRum.getUser()).length === 0) {
        datadogRum.setUser({
          id: user.id,
          name: user.fullName,
          email: user.email,
        });
      }
      const datadogUser = datadogRum.getUser();
      if (datadogUser) {
        if (datadogUser.company !== data.companyName) {
          datadogRum.setUserProperty('company', data.companyName);
        }
        if (datadogUser.affiliate !== data.companyAffiliate) {
          datadogRum.setUserProperty('affiliate', data.companyAffiliate);
        }
      }
    },
    trackCustomEvent(name, data = null) {
      window.Appcues?.track(name, data);
    },
    checkUrlAffiliate() {
      this.urlAffiliate = null;
      try {
        const splits = document.location.host.split('.');
        if (splits.length > 2 && ['dev', 'staging', 'qa', 'app'].indexOf(splits[0]) === -1) {
          const slug = splits[0];
          if (slug) {
            this.affiliateClient.getAffiliate(slug).then((res) => {
              this.urlAffiliate = res;
              this.checkedForUrlAffiliate = true;
            }).catch(() => {
              // this affiliate doesn't exist, go back to main site
              const testUrl = Vue.prototype.$globalEnv.webBaseUrl.replace('https://', '').replace('http://', '');
              const firstSub = testUrl.split('.')[0];

              const currentTestUrl = document.location.href.replace('https://', '').replace('http://', '');
              const currentFirstSub = currentTestUrl.split('.')[0];

              if (currentFirstSub !== firstSub) {
                window.location.href = Vue.prototype.$globalEnv.webBaseUrl;
              }
              this.checkedForUrlAffiliate = true;
            });
          } else {
            this.checkedForUrlAffiliate = true;
          }
        } else {
          this.checkedForUrlAffiliate = true;
        }
      } catch (ex) {
        console.log(ex);
        this.checkedForUrlAffiliate = true;
      }
      return null;
    },
    showCompanyAffiliate() {
      return !!this.selectedAffiliate || (this.selectedDashboard !== 'owner' && this.company() && this.company().affiliate);
    },
    affiliateLogo() {
      if (this.selectedAffiliate) {
        return this.selectedAffiliate.logo_url;
      }
      return this.company().affiliate.logo_url;
    },
    affiliateLightLogo() {
      if (this.selectedAffiliate) {
        return this.selectedAffiliate.light_logo_url;
      }
      return this.company().affiliate.light_logo_url;
    },
    async checkSelectedAffiliate() {
      // changing subdomains for shadow was causing issues and headaches so, for now, do not do redirects
      if (this.inShadowLoginMode()) return;

      const company = this.company();
      const pageSuffix = document.location.href.replace(document.location.origin, '');
      const baseWebUrl = Vue.prototype.$globalEnv.webBaseUrl;
      const brandWebUrl = Vue.prototype.$globalEnv.webBrandUrl;

      if (this.selectedAffiliate && company && company.affiliate && company.affiliate.id !== this.selectedAffiliate.id) {
        // go to company affiliate (if it doesn't match our subdomain)
        this.showSiteLoader = true;
        await this.saveCookie();
        window.location.href = `${brandWebUrl.replace('*', company.affiliate.subdomain)}${pageSuffix}`;
      } else if (company && !company.affiliate && this.selectedAffiliate) {
        // go to base helix b/c we are logged in with no affliate but we are on an affiliate page
        this.showSiteLoader = true;
        await this.saveCookie();
        if (pageSuffix.indexOf('affiliate') !== -1) {
          // don't let them go back to affiliate page when being kicked out of it
          let root = '';
          if (this.selectedDashboard === 'owner') {
            root = '/owner';
          } else if (this.selectedDashboard === 'sp') {
            root = '/vendor';
          }

          window.location.href = `${baseWebUrl}${root}`;
        } else {
          window.location.href = `${baseWebUrl}${pageSuffix}`;
        }
      }
    },
    async checkSubdomain(changedAffiliate = false) {
      // changing subdomains for shadow was causing issues and headaches so, for now, do not do redirects
      // also don't do it inside mobile app or when handling SSO
      if (this.inShadowLoginMode()
        || this.isInFlutterApp()
        || window.location.href.indexOf('sso') != -1
      ) return;

      try {
        if (!this.checkedForUrlAffiliate) {
          setTimeout(() => {
            this.checkSubdomain(changedAffiliate);
          }, 500);
          return;
        }

        if (window.location.href.indexOf('localhost') !== -1) {
          return;
        }

        const company = this.company();
        const companyAffiliate = this.selectedAffiliate || (company && company.affiliate) || null;

        const baseWebUrl = Vue.prototype.$globalEnv.webBaseUrl;
        const brandWebUrl = Vue.prototype.$globalEnv.webBrandUrl;
        const pageSuffix = document.location.href.replace(document.location.origin, '');
        const owner = this.selectedDashboard === 'owner';

        if (this.urlAffiliate && owner) {
          // owner will work in base helix
          this.showSiteLoader = true;
          await this.saveCookie();
          window.location.href = `${baseWebUrl}${pageSuffix}`;
          return;
        }

        if (!owner && !this.shadowAffiliate) {
          if (!changedAffiliate && this.urlAffiliate && this.selectedDashboard === 'affiliate') {
            // for affiliate dash, use the subdomain as selectedAffiliate instead of kicking you out based on the company affiliate
            if (!this.selectedAffiliate || this.selectedAffiliate.id !== this.urlAffiliate.id) {
              this.selectedAffiliate = this.urlAffiliate;
            }
            return;
          }

          if (companyAffiliate && (!this.urlAffiliate || (this.urlAffiliate && companyAffiliate.id !== this.urlAffiliate.id))) {
            // go to companyAffiliate (if it doesn't match our subdomain)
            this.showSiteLoader = true;
            await this.saveCookie();
            window.location.href = `${brandWebUrl.replace('*', companyAffiliate.subdomain)}${pageSuffix}`;
            return;
          }

          if (company && !companyAffiliate && this.urlAffiliate) {
            // go to base helix b/c we are logged in with no affliate but we are on an affiliate page
            this.showSiteLoader = true;
            await this.saveCookie();
            window.location.href = `${baseWebUrl}${pageSuffix}`;
          }
        }
      } catch (ex) {
        console.log(ex);
      }
    },

    showHelixFee() {
      return this.selectedDashboard === 'sp' || this.selectedDashboard === 'owner';
    },

    downloadCSV(csv, title) {
      return this.downloadFile(title, csv);
    },
    downloadFile(title, data, format = 'csv') {
      let fileName = `${title}`;
      if (format === 'XLS') {
        fileName = `${title}`;
      } else if (format === 'PDF') {
        fileName = `${title}`;
      }

      const downloadLink = document.createElement('a');
      downloadLink.download = fileName;

      if (format === 'XLS') {
        downloadLink.href = URL.createObjectURL(new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }));
      } else if (format === 'PDF') {
        downloadLink.href = URL.createObjectURL(new Blob([data], {
          type: 'application/pdf',
        }));
      } else {
        const blob = new Blob(['\ufeff', data]);
        downloadLink.href = URL.createObjectURL(blob);
      }

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    downloadFileFromUrl(url) {
      const anchor = document.createElement('a');
      anchor.setAttribute('download', 'download');
      anchor.href = url;
      document.body.appendChild(anchor);
      anchor.click();
      anchor.remove();
    },
    inShadowUserMode() {
      return !!this.shadowUser;
    },
    inShadowNonUserMode() {
      return this.shadowAffiliate || !!this.shadowCompany;
    },
    inShadowLoginMode() {
      return this.inShadowNonUserMode() || this.inShadowUserMode();
    },
    ownerInShadowMode() {
      return this.inShadowLoginMode() && this.user().isOwner;
    },
    routeAuthChanged() {
      this.checkSubdomain();
      if (this.route.name !== this.user()?.homeRouteName) {
        this.router.push({ name: this.user().homeRouteName });
      } else {
        this.saving = true;
        setTimeout(() => {
          document.location.reload();
        }, 0);
      }
    },
    async exitShadowLoginMode() {
      this.resetShadowVariables(true);

      const company = this.company();
      if (company && company.id !== this.companyId) {
        this.companyId = company.id;
      }

      if (!this.shadowStack?.length) {
        this.routeAuthChanged();
        return;
      }

      const currentShadowInfo = this.shadowStack.pop();
      this.setDashboard(currentShadowInfo.origin);

      if (this.shadowStack?.length) {
        await this.loadShadows();
      } else if (currentShadowInfo.origin === 'affiliate') {
        // if we are returning to an affiliate, make sure the correct one is stored
        this.selectedAffiliate = await this.affiliateClient.getAffiliateById(currentShadowInfo.originId);
      }

      if (currentShadowInfo.originPath) {
        this.router.push(currentShadowInfo.originPath);
      } else {
        this.routeAuthChanged();
      }
    },
    resetShadowVariables(resetCompany = false) {
      this.shadowUser = null;
      this.shadowCompany = null;
      this.shadowAffiliate = null;

      if (resetCompany) {
        const company = this.company();
        if (company && company.id !== this.companyId) {
          this.companyId = company.id;
        }
      }
      this.cachedData?.invalidateCache();
    },
    async loadShadows() {
      this.resetShadowVariables();

      if (!this.shadowStack?.length) {
        return;
      }

      const currentShadowInfo = this.currentShadowInfo;

      try {
        if (currentShadowInfo.entityType === 'company') {
          this.shadowCompany = { id: currentShadowInfo.entityId };
          this.shadowCompany = await this.companyClient.getCompanyShadow();
        } else if (currentShadowInfo.entityType === 'affiliate') {
          this.selectedAffiliate = await this.affiliateClient.getAffiliateById(currentShadowInfo.entityId);
          this.shadowAffiliate = !!this.selectedAffiliate;
        } else if (currentShadowInfo.entityType === 'user') {
          this.shadowUser = { id: currentShadowInfo.entityId };
          this.shadowUser = await this.userClient.getRemoteUser();
        }
      } catch (ex) {
        this.selectedAffiliate = null;
        this.shadowAffiliate = false;
        this.shadowCompany = null;
        this.shadowUser = null;
      }
    },
    setRefreshToken() {
      try {
        let warningTime = 0;
        let expireTime = 0;

        // get from refresh token
        const refreshToken = this.refreshToken;
        if (!refreshToken) {
          return;
        }

        const decoded = atob(refreshToken.split('.')[1]);
        const time = JSON.parse(decoded).exp * 1000;
        const timeUntil = time - new Date().getTime();

        if (timeUntil <= 0) {
          return;
        }
        expireTime = timeUntil;
        warningTime = timeUntil - (5 * 60e3);

        if (this.sessionWarningTimeout) {
          clearTimeout(this.sessionWarningTimeout);
        }

        if (this.sessionExpiredTimeout) {
          clearTimeout(this.sessionExpiredTimeout);
        }

        if (warningTime > 0) {
          this.sessionWarningTimeout = setTimeout(() => {
            if (this.user()) {
              const sessionModal = this.sessionModal();
              if (sessionModal) {
                sessionModal.showWarning();
              }
            }
          }, warningTime);
        }

        if (expireTime > 0) {
          this.sessionExpiredTimeout = setTimeout(() => {
            if (this.user()) {
              const sessionModal = this.sessionModal();
              if (sessionModal) {
                sessionModal.showExpired();
              }
            }
          }, expireTime);
        }
      } catch (ex) {
        console.warn(ex);
      }
    },
    async loginAsUser(user) {
      this.shadowUser = user;
      this.showSiteLoader = true;

      const company = user.currentCompany;
      await this.saveCookie();
      await this.refreshUser(company?.id);

      await this.setCompanyId(company?.id);
      this.showSiteLoader = false;
    },
    shadowAffiliateLogin(affiliate, origin = 'owner') {
      this.selectedAffiliate = affiliate;
      this.shadowAffiliate = true;
      this.setDashboard('affiliate');

      this.shadowStack.push({
        entityType: 'affiliate',
        entityId: affiliate.id,
        origin,
        originPath: this.route?.fullPath,
      });

      this.routeAuthChanged();
    },
    async shadowUserLogin(user, origin = 'owner') {
      this.shadowStack.push({
        entityType: 'user',
        entityId: user.id,
        origin,
        originPath: this.route?.fullPath,
      });

      if (!user.companies.length && user.isAffiliate) {
        this.setDashboard('affiliate');
      }

      await this.loginAsUser(user);

      this.saveCookie();
    },
    async shadowCompanyLogin(companyId, {
      dashboard = 'eo',
      origin,
      destination,
      originEntity,
    }) {
      try {
        // set the shadow company to the id so that we can get the full company return
        // as if the user was a member
        this.companyId = companyId;
        this.shadowCompany = { id: companyId };
        const company = await this.companyClient.getCompanyShadow();
        await this.setCompanyId(companyId, company);

        if (dashboard) {
          this.setDashboard(dashboard);
        }

        this.shadowStack.push({
          entityType: 'company',
          entityId: companyId,
          origin,
          originId: originEntity?.id,
          originName: originEntity?.name,
          originPath: this.route?.fullPath,
        });

        if (destination) {
          this.router.push(destination);
        } else {
          this.routeAuthChanged();
        }

        this.saveCookie();
      } catch (ex) {
        console.log(ex);
        this.showError('Failed to login as company.', ex);
      }
    },
    // await this method call so we can refresh flags before going anywhere
    async setCompanyId(companyId, shadowCompany, resetLastRoute) {
      // when changing company, we need to reset flags
      const user = this.user();

      if (shadowCompany) {
        this.shadowCompany = shadowCompany;
      } else {
        this.shadowCompany = null;
      }

      if (resetLastRoute) {
        this.lastRoute = null;
      }

      if (!companyId && user) {
        companyId = user.companyId;
      }

      // context switching
      this.companyId = companyId ? parseInt(companyId) : null;

      // refresh company specific cached global data
      await this.refreshFeatureFlags();
      await this.refreshCapabilities();

      // if the company changed, update the tracking to represent the new company
      this.removeTracking();
      this.activateTracking();

      this.pubSubUtil.connectToCurrentCompanyChannel();
      this.pubSubUtil.addListener(this);

      if (this.companyId) {
        if (this.userClient && user?.default_company_portal_id !== this.companyId) {
          if (user) {
            user.default_company_portal_id = this.companyId;
          }
          this.userClient.setDefaultCompany(this.companyId).catch(() => {});
        }

        if (this.lastRoute && this.lastRoute.meta && (this.lastRoute.meta.eoOnly || this.lastRoute.meta.spOnly)) {
          this.setDashboard(this.lastRoute.meta.eoOnly ? 'eo' : 'sp');
        } else if (!this.shadowAffiliate) {
          const company = shadowCompany || this.company();
          if (company) {
            if (company.is_service_provider && company.is_equipment_operator) {
              // last used
              const lastUsedDashboard = this.selectedDashboard;
              if (lastUsedDashboard && lastUsedDashboard === 'sp') {
                this.setDashboard('sp');
              } else {
                this.setDashboard('eo');
              }
            } else {
              this.setDashboard(company.is_service_provider ? 'sp' : 'eo');
            }
          } else if (user && user.managed_affiliates && user.managed_affiliates.length > 0) {
            this.setDashboard('affiliate');
          }
        }
      }
      // saveCookie is called in setDashboard ^
    },

    // eo|sp|owner|affiliate
    setDashboard(mode) {
      if (mode && this.selectedDashboard === mode) {
        // before this check was in place, many places called this and relied on it
        // to save the cookie, so continue to do that for now
        this.saveCookie();
      } else if (mode === 'owner') {
        // when going into owner, clear any old shadow instances
        this.resetShadowVariables(true);
        this.shadowStack = [];
      }

      this.selectedDashboard = mode || 'eo';

      if (this.selectedDashboard !== 'affiliate') {
        this.shadowAffiliate = false;
        this.selectedAffiliate = this.urlAffiliate || null;
        if (this.urlAffiliate) {
          this.checkSelectedAffiliate();
        }
      }

      this.saveCookie();
      this.activateTracking();

      if (this.selectedDashboard === 'owner') {
        this.getOwnerStats();
      }
    },

    getOwnerStats() {
      if (this.adminClient) {
        try {
          return this.adminClient.getMetricsCompanies().then((stats) => {
            this.stats = stats;
          });
        } catch (ex) {}
      }
    },

    refreshUser(shadowCompanyId = null) {
      if (this.userClient) {
        try {
          return this.userClient.getRemoteUser(shadowCompanyId).then((user) => {
            if (user) {
              if (this.shadowUser) {
                this.shadowUser = user;
              } else {
                this.setUser(user);
              }
              this.saveCookie();
              this.getUnreadCounts();
            }
          });
        } catch (ex) {}
      }
    },

    isFlagEnabled(flag) {
      const flagValue = this.userFeatureFlags[flag] || false;
      datadogRum.addFeatureFlagEvaluation(flag, flagValue);
      return flagValue;
    },

    // grabbing on app launch before siteLoader goes away
    // and refresh each 5min, automatically in the background
    // or on changeCompany
    async refreshFeatureFlags() {
      if (this.refreshFlagTimeout) {
        clearTimeout(this.refreshFlagTimeout);
        this.refreshFlagTimeout = null;
      }

      try {
        const flagResp = await this.userClient.getFeatureFlags();
        this.userFeatureFlags = flagResp;

        if (flagsRefreshTime) {
          this.refreshFlagTimeout = setTimeout(() => {
            this.refreshFeatureFlags();
          }, flagsRefreshTime);
        }
      } catch (ex) {
        console.warn(ex);
      }
    },

    isCapabilityAvailable(capability) {
      return this.companyCapabilities[capability] || false;
    },

    async refreshCapabilities() {
      if (this.refreshCapabilitiesTimeout) {
        clearTimeout(this.refreshCapabilitiesTimeout);
        this.refreshCapabilitiesTimeout = null;
      }

      try {
        this.companyCapabilities = await this.companyClient.getCompanyCapabilities();

        if (flagsRefreshTime) {
          this.refreshCapabilitiesTimeout = setTimeout(() => {
            this.refreshCapabilities();
          }, flagsRefreshTime);
        }
      } catch (ex) {
        console.warn(ex);
      }
    },

    showSuccess(msg, delay = null) {
      this.setGlobalAlert({
        msg,
        delay,
      });
    },
    showError(defaultError, ex) {
      let errorMessage = defaultError;

      if (ex && ex.body && ex.body.error && ex.body.error.extra) {
        if (ex.body.error.extra.detail) {
          errorMessage = ex.body.error.extra.detail;
        } else {
          const keys = Object.keys(ex.body.error.extra);
          if (keys.length) {
            errorMessage = ex.body.error.extra[keys[0]];
            if (typeof errorMessage !== 'string' && errorMessage.length) {
              errorMessage = errorMessage[0];
            }
          }
        }
      } else if (ex && ex.body && ex.body.error && ex.body.error.message) {
        errorMessage = ex.body.error.message;
      }

      this.setGlobalAlert({
        msg: errorMessage, error: true,
      });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollToInput(inputName) {
      const inputs = document.querySelectorAll(`[data-vv-name="${inputName}"],[name="${inputName}"]`);

      if (inputs && inputs.length) {
        inputs[0].scrollIntoView({ block: 'center' });
      } else {
        // input missing, try to find by data-vv-name
        console.log(`${inputName} not found!`);
      }
    },
    scrollToFirstError(validator) {
      if (validator && validator.errors.items.length) {
        const fieldId = validator.errors.items[0].field;

        let inputs = document.getElementsByName([fieldId]);
        if (inputs && inputs.length) {
          inputs[0].scrollIntoView({ block: 'center' });
        } else {
          // input missing, try to find by data-vv-name
          inputs = document.querySelectorAll(`[data-vv-name="${fieldId}"]`);

          if (inputs && inputs.length) {
            inputs[0].scrollIntoView({ block: 'center' });
          } else {
            console.log(`${fieldId} not found!`); // input missing entirely
          }
        }
      }
    },

    forceLogout() {
      if (window.location.href.indexOf('login') === -1 && window.location.href.indexOf('logout') === -1) {
        // stop sending them back to login or logout, if thats where they came from
        this.currentRoute = window.location.href;
        this.saveCookie();
      }

      // if we get stuck and siteLoader is still on that means we need to reset even if we are sitting on login
      if (this.showSiteLoader || (window.location.href.indexOf('logout') === -1 && window.location.href.indexOf('login') === -1)) {
        document.location.href = '/logout';
      }
    },
    isMobile() {
      return isMobile();
    },
    isInFlutterApp() {
      return !!window.flutterChannel;
    },
    formatCurrency(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },

    formatDate(date, noTime = false) {
      if (date) {
        if (noTime) {
          return date.toLocaleDateString();
        }
        return date.toLocaleString();
      }
      return '';
    },
    testRoutePermission() {
      let goingToAdminPage = false;
      let goingToAffiliatePage = false;

      if (this.lastRoute && this.user()) {
        goingToAdminPage = this.lastRoute.meta && this.lastRoute.meta.adminOnly;
        goingToAffiliatePage = this.lastRoute.meta && this.lastRoute.meta.affiliateOnly;

        if (this.selectedDashboard !== 'owner' && goingToAdminPage) {
          if (this.user().isOwner) {
            // switch to admin mode
            this.setDashboard('owner');
          } else {
            // can't view admin pages
            window.location.href = '/';
          }
        }

        if (this.selectedDashboard !== 'affiliate' && goingToAffiliatePage) {
          if (this.user().isAffiliate) {
            // switch to affiliate mode
            this.setDashboard('affiliate');
          } else {
            // can't view affiliate pages
            window.location.href = '/';
          }
        }
      }

      if (!goingToAdminPage && !goingToAffiliatePage && this.user()) {
        // handle leaving an owner page and going to EO or SP portal
        // but if its anon page, any role can go to it ..
        const pageRequiresCompany = this.lastRoute && this.lastRoute.meta && !this.lastRoute.meta.allowAnonymous;
        const company = this.company();

        if (pageRequiresCompany) {
          if (this.lastRoute.meta.eoOnly || this.lastRoute.meta.spOnly) {
            if (this.lastRoute.meta.eoOnly && (!company || company.is_equipment_operator)) {
              this.setDashboard('eo');
              return;
            }

            if (this.lastRoute.meta.spOnly && (!company || company.is_service_provider)) {
              this.setDashboard('sp');
              return;
            }
          }
        }

        // if we are leaving adminPage and the page requires EO or SP, change it - otherwise stay in admin

        if (this.lastRoute.meta.eoOnly || this.lastRoute.meta.spOnly) {
          if (company) {
            if (company.is_service_provider && company.is_equipment_operator) {
              // last used
              const lastUsedDashboard = this.selectedDashboard;
              if (lastUsedDashboard && lastUsedDashboard === 'sp') {
                this.setDashboard('sp');
              } else {
                this.setDashboard('eo');
              }
            } else {
              this.setDashboard(company.is_service_provider ? 'sp' : 'eo');
            }
          }
        }
      }
    },
    async onRouteChange(to) {
      if (this.gitHashTimeout === null) {
        this.refreshGitHash();
      }

      this.lastRoute = to || null;
      this.affiliateFiltersChanged = null;
      this.getUnreadCounts();

      if (this.BlockOpenSidebar) {
        this.BlockOpenSidebar = false;
        this.SideBarOpen = true;
      } else if (this.isMobile() && this.MobileMenuOpen) {
        this.toggleMobileMenu();
      }

      this.testRoutePermission();

      if (this.selectedDashboard === 'owner' && this.user() && this.user().isOwner) {
        this.getOwnerStats();
      }
    },
    testDashboardPermission(dashboard = 'eo', router = null) {
      if (this.selectedDashboard !== dashboard) {
        (router || Vue.$router).push({
          name: this.user().homeRouteName,
        });
      }
    },
    async refreshGitHash() {
      if (this.gitHashTimeout) {
        clearTimeout(this.gitHashTimeout);
        this.gitHashTimeout = null;
      }
      try {
        const response = await Vue.http.get('/version.txt', { params: { timestamp: Date.now() } });
        const hash = response.body.split('\n')[0];
        if (this.gitHash && hash && this.gitHash !== hash) {
          this.hasNewVersion = true;
          return;
        }

        this.gitHash = hash;
      } catch {}

      this.gitHashTimeout = setTimeout(() => {
        this.refreshGitHash();
      }, gitHashRefreshTime);
    },
    clearUnreadNotificationCount() {
      this.unreadNotifications = 0;
    },
    getUnreadCounts() {
      if (!this.notificationClient || !this.currentUser) {
        return;
      }

      if (this.notificationsTimeout) {
        clearTimeout(this.notificationsTimeout);
        this.notificationsTimeout = null;
      }

      if (!document.hasFocus()) {
        // try again later, window out of focus
        this.notificationsTimeout = setTimeout(() => {
          this.getUnreadCounts();
        }, this.focusTimeoutMs);
        return;
      }

      this.notificationClient.getNotificationCounts().then((count) => {
        this.unreadNotifications = count;

        if (this.unreadNotifications >= 10) {
          this.unreadNotifications = '9+';
        }

        // get again in 30sec if we are still sitting on this page
        this.notificationsTimeout = setTimeout(() => {
          this.getUnreadCounts();
        }, this.focusTimeoutMs);
      }).catch(() => {
        this.unreadNotifications = 0;
      });
    },
    toggleMobileMenu() {
      if (this.onCloseCB) {
        this.onCloseCB();
      }

      if (!this.MobileMenuOpen) {
        // first set width, then animate
        this.SideBarOpen = true;
      } else {
        // after a delay set width to 0
        setTimeout(() => {
          this.SideBarOpen = false;
        }, 500);
      }

      this.MobileMenuOpen = !this.MobileMenuOpen;
    },
    closeSettings() {
      // called on route change
      this.AccountMenuOpen = false;
      this.CreateMenuOpen = false;
    },
    toggleUserMenu() {
      this.justToggledNav = true;

      setTimeout(() => {
        this.justToggledNav = false;
      }, 500);

      this.AccountMenuOpen = !this.AccountMenuOpen;
    },
    closeUserMenu() {
      if (!this.justToggledNav) {
        this.AccountMenuOpen = false;
      }
    },
    closeSidebar() {
      if (this.isMobile() && this.MobileMenuOpen) {
        // after a delay set width to 0
        setTimeout(() => {
          this.SideBarOpen = false;
        }, 500);
        this.MobileMenuOpen = false;
      }
    },

    // globals
    setGlobalAlert(options) {
      this.globalErrorAlertText = '';
      this.globalSuccessAlertText = '';

      setTimeout(() => {
        if (options.error) {
          this.globalErrorAlertText = options.msg;
        } else {
          this.globalSuccessAlertText = options.msg;
        }

        if (options.delay) {
          this.globalAlertDelay = options.delay;
        } else {
          this.globalAlertDelay = null;
        }
      }, 100);
    },
    clearGlobalAlerts() {
      this.globalErrorAlertText = '';
      this.globalSuccessAlertText = '';
    },
    clearGlobalAlert(isError) {
      if (isError) {
        this.globalErrorAlertText = '';
      } else {
        this.globalSuccessAlertText = '';
      }
    },

    // authorization
    setUser(user) {
      if (user && this.shadowUser && this.shadowUser.id === user.id) {
        // do not set currentUser to be the shadowUser
        return;
      }

      const oldUser = this.currentUser;
      this.currentUser = user || null;

      if (user) {
        if (oldUser === null || oldUser.id !== user.id) {
          this.userLoggedIn();
        }
      } else {
        this.userLoggedOut();
      }
    },
    clearUser() {
      this.setUser(null);
    },
    userAcceptedTerms() {
      if (this.currentUser) {
        this.currentUser.require_terms_acceptance = false;
        this.activateTracking();
      }
    },
    getCookiePrefix() {
      return cookieName;
    },
    saveCookie() {
      // persist data in cookie for hsb.dev to share w/ dev
      const session = Vue.$cookies.get(cookieName) || {};
      session.currentRoute = this.currentRoute || '';
      session.companyId = this.companyId || 0;
      session.accessToken = this.accessToken || '';
      session.refreshToken = this.refreshToken || '';
      session.selectedDashboard = this.selectedDashboard || '';

      session.shadowAffiliateId = this.shadowAffiliate && this.selectedAffiliate ? this.selectedAffiliate.id : 0;
      session.shadowUser = (this.shadowUser && this.shadowUser.id) || null;
      session.shadowCompany = (this.shadowCompany && this.shadowCompany.id) || null;

      session.shadowStack = this.shadowStack || [];

      session.JUST_JOINED_COMPANY = this.JUST_JOINED_COMPANY || 0;
      return Vue.$cookies.set(cookieName, session);
    },
    userLoggedIn() {
      if (this.isInFlutterApp() && window.Notification) {
        // ask for push notification permissions
        window.Notification.requestPermission();
      }

      // let the app know the tokens
      window.HelixIntelFlutter?.setAuth?.(this.accessToken, this.refreshToken);
    },
    userLoggedOut() {
      this.resetShadowVariables();
      clearAll(['currentRoute']);
      this.setCompanyId(null);
      this.removeTracking();
    },
    setTokens(response) {
      if (response.access) {
        this.accessToken = response.access;
      }

      if (response.refresh) {
        this.refreshToken = response.refresh;
        this.setRefreshToken();
      }
      this.saveCookie();
    },

    openLightbox(url) {
      if (!this.lightboxImages) {
        this.lightboxIndex = null;
      } else {
        for (let i = 0; i < this.lightboxImages.length; i++) {
          const fileModel = this.lightboxImages[i];
          if (url === fileModel.thumbnail_url || url === fileModel.url) {
            this.lightboxIndex = i;
          }
        }
      }
    },
    setTopAlert(text = null, ctaText = null, ctaCallback = null) {
      this.topAlertText = text;
      this.topAlertCtaText = ctaText;
      this.topAlertCtaCallback = ctaCallback;
    },
    processPubSubEvent(event_type, data, wasLocalAction) {
      switch (event_type) {
        case 'task_timer_started':
        case 'task_timer_stopped':
          if (data?.user_id === this.user()?.id) {
            this.refreshUser();
          }
          break;
        default:
          break;
      }

      if (this.cachedData) {
        this.cachedData.processPubSubEvent(event_type, data, wasLocalAction);
      }
    },
    setPushToken(token, device_id, platform) {
      this.mobileAppDeviceId = device_id;
      this.userClient.setUserPushToken(token, this.mobileAppDeviceId, platform).catch(() => {});
    },
    setMobileKeyboardState(open, keyboardHeight) {
      // add a short delay here to give time for inputWithFocusFromTaskBar to be set
      const delay = open ? 300 : 0;
      setTimeout(() => {
        this.mobileKeyboardOpen = open;
        this.mobileKeyboardHeight = keyboardHeight;

        // We don't control the ZenDesk iframe directly, so we need to query it and adjust the padding
        const iframe = document.querySelector('iframe[name="Messaging window"]');
        if (iframe) {
          if (open) {
            iframe.style.paddingBottom = `${keyboardHeight}px`;
          } else {
            iframe.style.paddingBottom = '0px';
          }
        }

        // We need to add a class to modal headers when the keyboard opens
        // This is only for `TaskModal` currently but might work for any modal
        const modalHeader = document.querySelector('.modal-header');
        if (modalHeader) {
          if (open) {
            modalHeader.classList.add('keyboard-open');
          } else {
            modalHeader.classList.remove('keyboard-open');
          }
        }
      }, delay);
    },
  },
};

export default globals;
