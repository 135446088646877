import BaseModel from './BaseModel';

export default class NotificationModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.type = data.notification_type || ''; // NotificationType enums.py
    this.read = data.read || false;
    this.seen = data.seen || false;
    this.cleared = data.cleared || false;
    this.timestamp = data.timestamp ? new Date(data.timestamp) : null;

    this.heading = data.heading || '';
    this.message = data.message || '';

    this.rfp = data.rfp || null; // {id, name, reference_number }
    this.location_id = data.location_id || 0;
    this.weather_alert_id = data.weather_alert_id || 0;
    this.task_id = data.task_id || 0;
    this.work_order_request_id = data.work_order_request_id || '';
    this.reservation_request_id = data.reservation_request_id || '';
    this.reservation_id = data.reservation_id || '';
    this.comment_id = data.comment_id || 0;
    this.equipment_id = data.equipment_id || 0;
    this.incentifind_report_id = data.incentifind_report_id || 0;
    this.sensor_id = data.sensor_id || 0;

    this.related_company = data.related_company || null;
    this.batch_records = data.batch_records || null;

    this.websitePath = data.website_path || '/';
  }
}
