import BaseModel from './BaseModel';
import UserModel from './UserModel';

export default class ObjectLogModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.log_message = data.log_message || '';
    this.created = data.created ? new Date(data.created) : null;
    this.updated = data.updated ? new Date(data.updated) : null;
    this.user = data.user ? new UserModel(data.user) : null;

    this.field = data.field || null;
    this.old_value = data.old_value || null;
    this.old_value_display = data.old_value_display || this.old_value;
    this.old_value_details = data.old_value_details || {};
    this.new_value = data.new_value || null;
    this.new_value_display = data.new_value_display || this.new_value;
    this.new_value_details = data.new_value_details || {};
  }

  get log_display() {
    return this.log_message;
  }

  get isAddAttachment() {
    return this.field === 'attachments' && Boolean(this.new_value);
  }

  attachmentField() {
    if (this.old_value_display) return `removed an attachment: <strong>${this.old_value_display}</strong>.`;
    if (this.new_value_display) return `added an attachment: <strong>${this.new_value_display}</strong>.`;
  }
}
