import Vue from 'vue';

import CommentModel from './CommentModel';
import ObjectLogModel from './ObjectLogModel';
import UserModel from './UserModel';

import { countDisplay } from '../util';

export default class ReservationRequestLogModel extends ObjectLogModel {
  init(data) {
    super.init(data);

    this.reservation_request_id = data.reservation_request_id || 0;
    this.comment = data.comment ? new CommentModel(data.comment) : null;
    this.related_user = data.related_user ? new UserModel(data.related_user) : null;
  }

  get userDisplayName() {
    if (!this.user) {
      return '';
    }

    if (this.field === 'status') return 'HelixIntel';

    const loggedInUserId = Vue.prototype.$globals.user()?.id || 0;
    return this.user && this.user.id !== loggedInUserId
      ? this.user.fullName
      : 'You';
  }

  get log_display() {
    const reasonField = () => (this.log_message.replace(/: (.*)\./, ': <strong>$1</strong>.')); // bold any text between : and .
    const reviewersField = () => {
      if (this.old_value_display) return `removed reviewer <strong>${this.related_user?.fullName || this.old_value_display}</strong>.`;
      if (this.new_value_display) return `added reviewer <strong>${this.related_user?.fullName || this.new_value_display}</strong>.`;
    };
    const statusField = () => ('set the status to <strong>Expired</strong>.');

    const reviewField = () => {
      // when the value is a URL, link it (single approved)
      const urlRegex = /(https?:\/\/[^\s]+)/gi;
      if (urlRegex.test(this.new_value_display)) {
        return `Reservation Created: <strong><a href="${this.new_value_display}">View Reservation</a></strong>`;
      }

      // when it looks like a json object (partial approval)
      if (this.new_value_display.includes('{')) {
        const review_results = JSON.parse(this.new_value_display);
        const approvals = countDisplay(review_results.approved, 'occurrence');
        const denials = countDisplay(review_results.denied, 'occurrence');

        let expired = '';
        if (review_results.expired) {
          const expiredCount = countDisplay(review_results.expired, 'occurrence');
          expired = `<li>${expiredCount} expired</li>`;
        }

        return `${this.log_message}<ul class='reservation-review'><li>${approvals} approved</li><li>${denials} denied</li>${expired}</ul>`;
      }

      return this.log_message; // otherwise use API's message (multiple approved)
    };

    const logMessageMap = new Map([
      ['canceled_reason', reasonField],
      ['denied_reason', reasonField],
      ['reviewers', reviewersField],
      ['status', statusField],
      ['review_reservation_create', reviewField],
      ['attachments', () => this.attachmentField()],
    ]);

    return logMessageMap.has(this.field)
      ? logMessageMap.get(this.field)()
      : this.log_message;
  }
}
