import Vue from 'vue';

export default class CachedCompanyData {
  constructor() {
    this.lastRefresh = 0;
    this.cachedData = null;
    this.isRetrievingData = false;
    this.companyId = null;
  }

  async getData(forceRefresh = false) {
    const company = Vue.prototype.$globals.company();
    if (!company && this.invalidateCacheOnCompanyChange) {
      return [];
    }

    // if another request is already getting the data, wait for that one to return
    let waitCount = 100;
    while (this.isRetrievingData && waitCount >= 0) {
      waitCount--;
      // eslint-disable-next-line no-promise-executor-return
      await new Promise(r => setTimeout(r, 10));
    }
    // if we have cached data, it hasn't expired,
    // and it is for the same company, return it
    if (!forceRefresh
      && this.cachedData
      && (!this.invalidateCacheOnCompanyChange || company.id === this.companyId)
      && new Date().getTime() < this.lastRefresh + this.getCacheExpiration()) {
      return this.cachedData;
    }

    this.isRetrievingData = true;

    try {
      this.cachedData = await this.retrieveData();
      this.lastRefresh = new Date().getTime();
      this.companyId = company.id;
    } catch (ex) {
      console.log(ex);
      this.lastRefresh = 0;
    }

    this.isRetrievingData = false;

    return this.cachedData;
  }

  async retrieveData() {
    console.error('Override retrieveData to do something useful');
  }

  getCacheExpiration() {
    // override to change cache expiration
    return 60000;
  }

  get invalidateCacheOnCompanyChange() {
    return true;
  }

  invalidateCache() {
    this.lastRefresh = 0;
  }
}
