import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';

import BaseModel from './BaseModel';
import FileModel from './FileModel';
import UserModel from './UserModel';
import TaskModel from './TaskModel';
import TaskTimeModel from './TaskTimeModel';
import { PropertyLocationModel } from './LocationModels';
import { titleCase } from '../util';
import filters from '../filters';

class EventDetailModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    // Assign a random UUID from the browser's crypto API if available,
    // otherwise generate a random string
    this.key = data.key
      ? data.key
      : (typeof window !== 'undefined' && window.crypto && window.crypto.randomUUID)
        ? window.crypto.randomUUID()
        : `${Date.now()}-${Math.random().toString(36).substring(2, 11)}`;
    this.start_time = data.start_time ? new Date(data.start_time) : null;
    this.end_time = data.end_time ? new Date(data.end_time) : null;
    this.location = data.location ? PropertyLocationModel.factory(data.location) : null;
  }

  get name() {
    return 'EventDetailModel'; // override in child class
  }
}

const ReservationRoutes = [
  'reservations-table',
  'reservations-calendar',
  'reservations-table-archived',
  'reservations-calendar-archived',
];

const ReservationRequestStatus = {
  PENDING_REVIEW: 'pending_review',
  CANCELED: 'canceled',
  REVIEWED: 'reviewed',
  EXPIRED: 'expired',
};

const ReservationRequestReviewStatus = {
  CANCELED: 'canceled',
  APPROVED: 'approved',
  DENIED: 'denied',
  PARTIAL: 'partial',
  EXPIRED: 'expired',
};

const ReservationStatus = {
  PENDING_REVIEW: 'pending_review',
  CANCELED: 'canceled',
  DENIED: 'denied',
  APPROVED: 'approved',
  EXPIRED: 'expired',
};

const ReservationStatusUi = {
  [ReservationStatus.PENDING_REVIEW]: { id: ReservationStatus.PENDING_REVIEW, display_label: 'Pending Review', color: 'E9C547' },
  [ReservationStatus.APPROVED]: { id: ReservationStatus.APPROVED, display_label: 'Approved', color: '2E933C' },
  [ReservationStatus.CANCELED]: { id: ReservationStatus.CANCELED, display_label: 'Canceled', color: 'CCC' },
  [ReservationStatus.DENIED]: { id: ReservationStatus.DENIED, display_label: 'Denied', color: 'E63462' },
};

const ReservationRequestModalMode = {
  RESERVATION: 'reservation',
  REQUEST: 'request',
};

class ReservationModel extends EventDetailModel {
  constructor(data, args) {
    data.depthSingle = args && 'depthSingle' in args ? args.depthSingle : true;
    super();
    this.init(data || {});
  }

  init(data) {
    if (Object.keys(data).length === 0) return;

    super.init(data); // see EventDetailModel

    this.user = data.user ? new UserModel(data.user) : null;
    this.status = data.status || 'approved';
    this.isUncommitted = false;
    this.notify_days_before_end_time = data.notify_days_before_end_time || 1;
    this.attachments = (data.attachments || []).map(f => new FileModel(f));

    if (!data.depthSingle) {
      this.request = data.request ? new ReservationRequestModel(data.request) : null;
    } else {
      this.request = data.request ? new ReservationRequestModel({ id: data.request.id, name: data.request.name, description: data.request.description }) : null;
    }

    this.tasks = (data.tasks || []).map(t => new TaskModel(t));

    if ('available' in data) {
      this.available = data.available;
    }

    this.commentCount = data.comment_count || 0;
    this.unreadCommentCount = data.unread_comment_count || 0;
    this.isClickable = data.isClickable ?? true;

    this.multiDayIndex = 1;
  }

  get duration() {
    const time_in_seconds = moment(this.end_time).diff(moment(this.start_time), 'second');
    const model = new TaskTimeModel({
      start_time: this.start_time,
      end_time: this.end_time,
      time_in_seconds,
    });
    return model.display_value;
  }

  get durationInDays() {
    if (!this.start_time || !this.end_time) return 0;

    return moment(this.end_time).diff(this.start_time, 'days') + 1;
  }

  get startWeek() {
    return moment(this.start_time).week();
  }

  get endWeek() {
    return moment(this.end_time).week();
  }

  /** does event span multiple calendar weeks? meaningful for calendar rendering */
  get spansWeeks() {
    return this.endWeek > this.startWeek;
  }

  get name() {
    if (this.request) {
      return this.request.name;
    }
    return '';
  }

  get requesterName() {
    return this.user?.fullName || '';
  }

  get display_label() {
    if (this.request) {
      return `${this.request.name} (${filters.dateShort(this.start_time)}, ${filters.timeOnly(this.start_time)})`;
    }
    return '';
  }

  get detailDisplay() {
    if (!this.start_time || !this.location) return '';

    const timeOfDay = moment(this.start_time).format('h:mmA');
    const location = this.location?.name;

    return `<strong>${timeOfDay}</strong> - ${location}`;
  }

  get archived() {
    return moment(this.end_time).isBefore(moment()); // does this need approved status too?
  }

  get isReadOnly() {
    const roStatus = [ReservationStatus.EXPIRED, ReservationStatus.CANCELED, ReservationStatus.DENIED].indexOf(this.status) !== -1;
    return this.archived || roStatus;
  }

  get canCancel() {
    return this.status === ReservationStatus.APPROVED && !this.archived;
  }

  get readOnlyMessageDetails() {
    let icon = ['fas', 'circle-exclamation'];
    let iconClass = {};
    let statusVerb = 'been canceled';

    if (this.status !== ReservationStatus.CANCELED) {
      icon = ['fas', 'check-circle'];
      iconClass = { green: true };
      statusVerb = 'already occurred';
    }

    if (this.archived) {
      iconClass = {}; // unset = gray
    }

    const message = `This reservation has ${statusVerb}. The information shown below is read-only.`;

    return {
      icon,
      iconClass,
      message,
    };
  }

  get calendarClass() {
    const classes = {};
    classes[this.status] = true;

    if (moment(this.end_time).isBefore(moment())) {
      classes.past = true;
    }

    if (this.spansWeeks) {
      classes.multiweek = true;
    }

    if (this.editing) {
      classes.editing = true;
    }

    return classes;
  }
}

class ReservationRequestModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.description = data.description || '';
    this.created = data.created ? new Date(data.created) : null;
    this.status = data.status || '';
    this.reviewed_status = data.reviewed_status || '';
    this.user = data.user ? new UserModel(data.user) : null;
    this.reviewers = (data.reviewers || []).map(a => new UserModel(a));
    this.reviewed_by = data.reviewed_by ? new UserModel(data.reviewed_by) : null;
    this.attachments = (data.attachments || []).map(f => new FileModel(f));
    this.reservations = (data.reservations || []).map(r => new ReservationModel(r));

    this.commentCount = data.comment_count || 0;
    this.unreadCommentCount = data.unread_comment_count || 0;
  }

  get isReadOnly() {
    return this.reviewed_status === ReservationRequestReviewStatus.DENIED
      || this.reviewed_status === ReservationRequestReviewStatus.APPROVED
      || this.reviewed_status === ReservationRequestReviewStatus.PARTIAL
      || this.status === ReservationRequestStatus.CANCELED
      || this.status === ReservationRequestStatus.EXPIRED;
  }

  get readOnlyMessageDetails() {
    const iconMap = new Map([
      [ReservationRequestReviewStatus.APPROVED, ['fas', 'circle-check']],
      [ReservationRequestReviewStatus.DENIED, ['far', 'ban']],
      [ReservationRequestReviewStatus.PARTIAL, ['fas', 'circle-exclamation']],
      [ReservationRequestReviewStatus.CANCELED, ['fas', 'circle-exclamation']],
      [ReservationRequestReviewStatus.EXPIRED, ['fas', 'calendar-exclamation']],
    ]);

    const colorMap = new Map([
      [ReservationRequestReviewStatus.APPROVED, { green: true }],
      [ReservationRequestReviewStatus.DENIED, { red: true }],
      [ReservationRequestReviewStatus.PARTIAL, { blue: true}],
      [ReservationRequestReviewStatus.CANCELED, { blue: true }],
      [ReservationRequestReviewStatus.EXPIRED, { red: true }],
    ]);

    const statusVerb = (this.reviewed_status || ReservationRequestReviewStatus.APPROVED).split('_').join(' ');
    const expiredMsg = (this.reviewed_status === ReservationRequestReviewStatus.EXPIRED) ? '' : 'been ';
    let message = `This reservation request has ${expiredMsg}${statusVerb}. The information below is read-only.`;

    if (this.reviewed_status === ReservationRequestReviewStatus.PARTIAL) {
      message = 'The review of this request has been completed. The information below is read-only.';
    }

    const icon = iconMap.get(this.reviewed_status);
    const iconClass = colorMap.get(this.reviewed_status);

    return {
      icon,
      iconClass,
      message,
    };
  }

  get requesterName() {
    return this.user?.fullName || '';
  }

  get reviewerName() {
    return this.reviewed_by?.fullName || '';
  }

  get statusDisplay() {
    return titleCase(this.status);
  }

  get isExpired() {
    return this.status === ReservationRequestStatus.EXPIRED;
  }

  get reviewedStatusDisplay() {
    if (this.reviewed_status === 'partial') {
      return 'Approved (Partial)';
    }
    return titleCase(this.reviewed_status || this.status || Vue.prototype.$globals.defaultDisplayValue);
  }

  get canCancel() {
    return this.status === 'pending_review' && this.user?.id === Vue.prototype.$globals.user()?.id;
  }

  // a de-duplicated list of start times - mostly to see if we have multiple
  get reservationStartTimes() {
    if (!this.reservations.length) return [];

    return _.uniqWith(this.reservations, (a, b) => (a.start_time?.toString() === b.start_time?.toString())).map(r => r.start_time);
  }

  get hasMultipleStartTimes() {
    return this.reservationStartTimes.length > 1;
  }

  get reservationEndTimes() {
    if (!this.reservations.length) return [];

    return _.uniqWith(this.reservations, (a, b) => (a.end_time?.toString() === b.end_time?.toString())).map(r => r.end_time);
  }

  get hasMultipleEndTimes() {
    return this.reservationEndTimes.length > 1;
  }

  get reservationDurations() {
    if (!this.reservations.length) return [];
    return _.zip(this.reservationStartTimes, this.reservationEndTimes).map((r) => {
      const start_time = r[0];
      const end_time = r[1];
      const time_in_seconds = moment(end_time).diff(moment(start_time), 'second');
      const model = new TaskTimeModel({
        start_time,
        end_time,
        time_in_seconds,
      });
      return model.display_value;
    });
  }

  get hasMultipleDurations() {
    return this.reservationDurations.length > 1;
  }

  get reservationLocations() {
    if (!this.reservations.length) return [];

    return _.uniqWith(this.reservations, (a, b) => (a.location?.id === b.location?.id)).map(r => r.location);
  }

  get hasMultipleLocations() {
    return this.reservationLocations.length > 1;
  }

  get locationName() {
    return !this.hasMultipleLocations
      ? this.reservationLocations[0].fullAncestryPath
      : 'Multiple';
  }
}

export {
  EventDetailModel,
  ReservationRoutes,
  ReservationRequestStatus,
  ReservationRequestReviewStatus,
  ReservationStatus,
  ReservationStatusUi,
  ReservationRequestModalMode,
  ReservationModel,
  ReservationRequestModel,
};
