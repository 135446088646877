import Vue from 'vue';
import ObjectLogModel from './ObjectLogModel';
import CommentModel from './CommentModel';
import TaskTimeModel from './TaskTimeModel';
import UserModel from './UserModel';
import { getTaskPriorityLabel } from '../constants/options.js';
import filters from '../filters';
import { escapeHtml } from '../util';

export default class TaskLogModel extends ObjectLogModel {
  constructor(data, args) {
    super();

    this.init(data || {});
    this.workOrderRequestLog = args?.workOrderRequestLog || false;
  }

  init(data) {
    super.init(data);
    this.task_instance_due_date_display = data.task_instance_due_date_display;
    this.old_project_name = data.old_project ? data.old_project.name : null;
    this.new_project_name = data.new_project ? data.new_project.name : null;

    this.old_status_name = data.old_status ? data.old_status.name : null;
    this.new_status_name = data.new_status ? data.new_status.name : null;

    this.old_category_name = data.old_category ? data.old_category.name : null;
    this.new_category_name = data.new_category ? data.new_category.name : null;

    this.location_name = data.location ? data.location.name : null;
    this.equipment_name = data.equipment ? data.equipment.name : null;
    this.part_name = data.part ? data.part.name : null;
    this.proposal_name = data.rfp ? data.rfp.name : null;

    this.comment = data.comment ? new CommentModel(data.comment) : null;

    this.checklist_name = data.checklist?.name || null;
    this.checklist_item_name = data.checklist_item?.name || null;

    this.show_instance_date_on_task_log = data.show_instance_date_on_task_log || false;
    this.related_user = data.related_user ? new UserModel(data.related_user) : null;
    this.related_date_display = data.related_date_display || null;

    this.taskTypeDisplay = data.task_type_display || 'task';

    this.template = data.template || null;

    this.pm_schedule = data.pm_schedule || null;

    if (this.taskTypeDisplay === 'pm work order') {
      this.taskTypeDisplay = 'PM Work Order';
    }

    // do some cleanup so that the data is easier to use
    switch (this.field) {
      case 'location_id':
      case 'part_id':
      case 'equipment_id':
      case 'reservation_id':
        this.field = this.field.replace('_id', '');
        break;
      case 'rfp_id':
        this.field = 'request';
        break;
      default:
        break;
    }

    this.timeTrackingOld = this.field?.startsWith('time_tracking') && this.old_value_details ? new TaskTimeModel(this.old_value_details) : null;
    this.timeTrackingNew = this.field?.startsWith('time_tracking') && this.new_value_details ? new TaskTimeModel(this.new_value_details) : null;
  }

  get canCollapse() {
    // if we are a comment, we don't collapse
    return !this.comment;
  }

  get userDisplayName() {
    if (!this.user) {
      switch (this.field) {
        case 'reviewers':
          return 'An Automated Workflow';
        default:
          return '';
      }
    }

    const loggedInUserId = Vue.prototype.$globals.user()?.id || 0;
    return this.user && this.user.id !== loggedInUserId
      ? this.user?.fullName || 'Unknown'
      : 'You';
  }

  /* eslint-disable no-case-declarations */
  get log_display() {
    if (!this.field) return this.log_message;

    let objTypeName = 'task';

    if (this.workOrderRequestLog) {
      objTypeName = 'work request';
    }

    switch (this.field) {
      case 'self':
        return `created this ${objTypeName}.`;
      case 'template_self':
        if (this.template) {
          return `created this task from the work item template <b>${escapeHtml(this.template.name)}</b>.`;
        }
        break;
      case 'completed':
      case 'completed_update':
        if ((this.new_value && this.new_value.toLowerCase() === 'true') || this.field === 'completed_update') {
          const verb = this.field === 'completed' ? 'marked' : 'updated';
          let completedMessage = `${verb} this ${this.taskTypeDisplay} as `;

          const displayRelatedDate = this.related_date_display && (this.field === 'completed_update' || filters.dateOnly(this.created) !== this.related_date_display);

          if (displayRelatedDate || this.related_user) {
            completedMessage += 'having been <strong>completed</strong>';

            if (this.related_user) {
              completedMessage += ` by <strong>${escapeHtml(this.related_user.fullName)}</strong>`;
            }

            if (displayRelatedDate) {
              completedMessage += ` on <strong>${this.related_date_display}</strong>`;
            }
          } else {
            completedMessage += '<strong>completed</strong>';
          }

          return `${completedMessage}.`;
        }
        break;
      case 'archived':
        if (this.new_value.toLowerCase() === 'true') {
          return `<strong>closed</strong> the ${this.taskTypeDisplay}.`;
        }
        return `<strong>reopened</strong> the ${this.taskTypeDisplay}.`;
      case 'instance_archived':
        if (!this.task_instance_due_date_display || !this.new_value) break;
        if (this.new_value.toLowerCase() === 'true') {
          return `skipped the ${this.taskTypeDisplay} occurrence due on <strong>${this.task_instance_due_date_display}</strong>.`;
        }
        return `restored the ${this.taskTypeDisplay} occurrence due on <strong>${this.task_instance_due_date_display}</strong>.`;
      case 'name':
        return `updated the ${this.taskTypeDisplay} name from <strong>${escapeHtml(this.old_value_display)}</strong> to <strong>${escapeHtml(this.new_value_display)}</strong>.`;
      case 'due_date':
        if (this.old_value_display && this.new_value_display) {
          return `changed the due date from <strong>${this.old_value_display}</strong> to <strong>${this.new_value_display}</strong>.`;
        }
        if (this.old_value_display) {
          return `removed the due date of <strong>${this.old_value_display}</strong>`;
        }
        if (this.new_value_display) {
          return `set the due date as <strong>${this.new_value_display}</strong>`;
        }
        break;
      case 'reservation_status':
        if (this.new_value_display) {
          return `canceled reservation <strong>${escapeHtml(this.new_value_display)}</strong>.`;
        }
        break;
      case 'reservation':
        if (this.old_value_display && this.new_value_display) {
          return `changed the associated reservation from <strong>${escapeHtml(this.old_value_display)}</strong> to <strong>${escapeHtml(this.new_value_display)}</strong>.`;
        }
        if (this.old_value_display) {
          return `removed the associated reservation: <strong>${escapeHtml(this.old_value_display)}</strong>.`;
        }
        if (this.new_value_display) {
          return `set the associated reservation: <strong>${escapeHtml(this.new_value_display)}</strong>.`;
        }
        break;
      case 'location':
      case 'proposal':
      case 'request':
      case 'part':
      case 'equipment':
        if (this.old_value_display) {
          const display = escapeHtml(this[`${this.field}_name`] || this.old_value_display);
          return `removed the associated ${this.field}: <strong>${display}</strong>.`;
        }
        if (this.new_value_display) {
          const display = escapeHtml(this[`${this.field}_name`] || this.new_value_display);
          return `set the associated ${this.field}: <strong>${display}</strong>.`;
        }
        break;
      case 'assignees':
        if (this.old_value_display) return `unassigned <strong>${escapeHtml(this.related_user?.fullName) || 'Unknown'}</strong>.`;
        if (this.new_value_display) return `assigned <strong>${escapeHtml(this.related_user?.fullName) || 'Unknown'}</strong>.`;
        break;
      case 'reviewers':
        if (this.old_value_display) return `removed reviewer <strong>${escapeHtml(this.related_user?.fullName || this.old_value_display)}</strong>.`;
        if (this.new_value_display) return `added reviewer <strong>${escapeHtml(this.related_user?.fullName || this.new_value_display)}</strong>.`;
        break;
      case 'attachments':
        return this.attachmentField();
      case 'priority':
        return `updated the priority from <strong>${getTaskPriorityLabel(this.old_value)}</strong> to <strong>${getTaskPriorityLabel(this.new_value)}</strong>.`;
      case 'project_id':
        if (this.old_project_name && this.new_project_name) {
          return `updated the associated group from <strong>${escapeHtml(this.old_project_name)}</strong> to <strong>${escapeHtml(this.new_project_name)}</strong>.`;
        }
        break;
      case 'category_id':
        const old_category_display = escapeHtml(this.old_category_name || this.old_value_display);
        const new_category_display = escapeHtml(this.new_category_name || this.new_value_display);
        if (old_category_display && new_category_display) return `updated the category from <strong>${old_category_display}</strong> to <strong>${new_category_display}</strong>.`;
        if (!old_category_display) return `updated the category to <strong>${new_category_display}</strong>.`;
        if (!new_category_display) return `removed the category <strong>${old_category_display}</strong>.`;
        break;
      case 'work_type':
        if (this.new_value && this.old_value) return `updated the work type from <strong>${this.old_value_display}</strong> to <strong>${this.new_value_display}</strong>.`;
        if (!this.old_value) return `updated the work type to <strong>${this.new_value_display}</strong>.`;
        if (!this.new_value) return `removed the work type <strong>${this.old_value_display}</strong>.`;
        break;
      case 'status_id':
        if (this.old_status_name && this.new_status_name) {
          return `changed the status from <strong>${escapeHtml(this.old_status_name)}</strong> to <strong>${escapeHtml(this.new_status_name)}</strong>`;
        }
        break;
      case 'pm_schedule_self':
        return `This PM Work Order was generated by <strong>${escapeHtml(this.pm_schedule.name)}</strong>`;
      case 'time_tracking_started':
        return 'started tracking time';
      case 'time_tracking_ended':
        const timeTrackingEndedStart = filters.timeOnly(this.timeTrackingNew.start_time);
        const timeTrackingEndedStop = filters.timeOnly(this.timeTrackingNew.end_time);
        if (timeTrackingEndedStart == timeTrackingEndedStop) {
          return `tracked <strong>${this.timeTrackingNew.display_value}</strong> at <strong>${timeTrackingEndedStop}</strong>`;
        }
        return `tracked <strong>${this.timeTrackingNew.display_value}</strong> from <strong>${timeTrackingEndedStart}</strong> to <strong>${timeTrackingEndedStop}</strong>`;
      case 'time_tracking_manual_entry':
        return `tracked <strong>${this.timeTrackingNew.display_value}</strong> on <strong>${filters.dateOnly(this.timeTrackingNew.manualEntryDateDate)}</strong>`;
      case 'time_tracking_updated':
        return this.getTimeTrackingUpdatedLogMessage();
      case 'time_tracking_deleted':
        return this.getTimeTrackingDeletedLogMessage('');
      case 'checklist':
        return `created checklist <strong>${escapeHtml(this.checklist_name)}</strong>`;
      case 'checklist_deleted':
        return `deleted checklist <strong>${escapeHtml(this.checklist_name)}</strong>`;
      case 'checklist_item':
        return `added <strong>${escapeHtml(this.checklist_item_name)}</strong> to checklist <strong>${escapeHtml(this.checklist_name)}</strong>`;
      case 'checklist_item_completed':
        if (this.new_value.toLowerCase() === 'true') {
          return `checked <strong>${escapeHtml(this.checklist_item_name)}</strong> on checklist <strong>${escapeHtml(this.checklist_name)}</strong>`;
        }
        return `unchecked <strong>${escapeHtml(this.checklist_item_name)}</strong> on checklist <strong>${escapeHtml(this.checklist_name)}</strong>`;
      case 'checklist_item_deleted':
        return `deleted <strong>${escapeHtml(this.checklist_item_name)}</strong> from checklist <strong>${escapeHtml(this.checklist_name)}</strong>`;
      case 'checklist_item_assignees':
        if (this.old_value_display) return `unassigned <strong>${escapeHtml(this.related_user?.fullName) || 'Unknown'}</strong> from checklist item <strong>${escapeHtml(this.checklist_item_name)}</strong>.`;
        if (this.new_value_display) return `assigned <strong>${escapeHtml(this.related_user?.fullName) || 'Unknown'}</strong> to checklist item <strong>${escapeHtml(this.checklist_item_name)}</strong>.`;
        break;
      case 'watcher':
        if (this.related_user?.id === this.user?.id) {
          if (this.new_value) return 'started watching.';
          if (this.old_value) return 'stopped watching.';
        }
        if (this.new_value) return `added watcher <strong>${escapeHtml(this.related_user?.fullName) || 'Unknown'}</strong>.`;
        if (this.old_value) return `removed watcher <strong>${escapeHtml(this.related_user?.fullName) || 'Unknown'}</strong>.`;
        break;
      case 'checklist_batch_add_assignee':
        return `assigned all items on checklist <strong>${escapeHtml(this.checklist_name)}</strong> to <strong>${escapeHtml(this.related_user?.fullName) || 'Unknown'}</strong>`;
      case 'checklist_batch_unassign':
        return `removed all users from checklist <strong>${escapeHtml(this.checklist_name)}</strong>`;
      case 'checklist_batch_completed':
        return `checked all items on checklist <strong>${escapeHtml(this.checklist_name)}</strong>`;
      case 'checklist_batch_uncompleted':
        return `unchecked all items on checklist <strong>${escapeHtml(this.checklist_name)}</strong>`;
      default:
        return this.log_message;
    }
    return this.log_message;
  }

  get taskOccurrenceDisplayDate() {
    if (this.field && this.task_instance_due_date_display && this.show_instance_date_on_task_log) {
      switch (this.field) {
        case 'time_tracking_updated':
        case 'time_tracking_deleted':
        case 'time_tracking_manual_entry':
        case 'time_tracking_ended':
        case 'time_tracking_started':
        case 'status_id':
        case 'checklist_item_completed':
        case 'checklist_batch_completed':
        case 'checklist_batch_uncompleted':
        case 'completed':
        case 'completed_update':
          return this.task_instance_due_date_display;
        default:
          return null;
      }
    }

    return null;
  }

  getTimeTrackingUpdatedLogMessage() {
    const oldTime = this.timeTrackingOld.display_value;
    const newTime = this.timeTrackingNew.display_value;
    const oldDate = filters.dateOnly(this.timeTrackingOld.manualEntryDateDate || this.timeTrackingOld.end_time);
    const newDate = filters.dateOnly(this.timeTrackingNew.manualEntryDateDate);

    let message = 'updated time tracked ';
    // updated another user's time
    if (this.user?.id != this.related_user?.id) {
      message += `by <strong>${escapeHtml(this.related_user?.fullName) || 'Unknown'}</strong> `;
    }

    if (oldTime != newTime && oldDate != newDate) {
      message += `from <strong>${oldTime}</strong> on <strong>${oldDate}</strong> to <strong>${newTime}</strong> on <strong>${newDate}</strong>`;
    } else if (oldTime != newTime) {
      message += `on <strong>${newDate}</strong> from <strong>${oldTime}</strong> to <strong>${newTime}</strong>`;
    } else {
      message += `of <strong>${newTime}</strong> from <strong>${oldDate}</strong> to <strong>${newDate}</strong>`;
    }

    return message;
  }

  getTimeTrackingDeletedLogMessage() {
    const time = this.timeTrackingOld.display_value;
    const dateDisplay = filters.dateOnly(this.timeTrackingOld.manualEntryDateDate || this.timeTrackingOld.end_time);

    let message = 'removed tracked time';
    // updated another user's time
    if (this.user?.id != this.related_user?.id) {
      message = `removed time tracked by <strong>${escapeHtml(this.related_user?.fullName) || 'Unknown'}</strong>`;
    }

    message += ` of <strong>${time}</strong> on <strong>${dateDisplay}</strong>`;

    return message;
  }
}
