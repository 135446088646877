import _ from 'lodash';
import { titleCase, scheduleIntervalToDescription, scheduleWeekdayToDescription, nth, scheduleMonthToDescription } from '../util';
import BaseModel from './BaseModel';

export default class ScheduleModel extends BaseModel {
  static ScheduleTypeBase = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Yearly: 'yearly',
  };

  static ScheduleType = {
    Daily: 'daily',
    Weekly: 'weekly',
    MonthlyOnDates: 'monthly_on_dates',
    MonthlyDayOfWeek: 'monthly_day_of_week_occurrences',
    YearlyOnDates: 'yearly_on_dates',
    YearlyDayOfWeek: 'yearly_day_of_week_occurrences',
  };

  static monthlyOnDateScheduleDefault = { day: 1 };

  static monthlyDayOfWeekScheduleDefault = { occurrence: 1, weekday: 0 };

  static yearlyOnDateScheduleDefault = { month: 1, day: 1 };

  static yearlyOnDayOfWeekScheduleDefault = { month: 1, weekday: 0, occurrence: 1 };

  init(data) {
    this.id = data.id || 0;

    this.schedule_type = this.parseScheduleType(data.schedule_type);
    this.schedule_type_base = (this.schedule_type || '').split('_')?.[0] || '';
    this.schedule_repeat_type = (data.schedule_type || '').includes('dates') ? 'numeric' : 'weekday';
    this.schedule_interval = data.schedule_interval || 0;
    this.schedule_lead_time = data.schedule_lead_time || 0;
    this.schedule_start_date = data.schedule_start_date || '';
    this.schedule_end_date = data.schedule_end_date || '';
    this.schedule_data = data.schedule_data || [];

    this.occurrences = [...this.schedule_data];

    this.cachedScheduleFields = {
      schedule_type: this.schedule_type,
      schedule_interval: this.schedule_interval,
      schedule_start_date: this.schedule_start_date,
      schedule_end_date: this.schedule_end_date,
    };
  }

  _detectChanges(cacheObj, currentValues) {
    const fieldNames = Object.keys(cacheObj);
    const updatedFields = fieldNames.filter(field => (cacheObj[field] !== currentValues[field]));

    return !!updatedFields.length;
  }

  parseScheduleType(type) {
    return Object.values(ScheduleModel.ScheduleType).includes(type) ? type : null;
  }

  validateScheduleOccurrences() {
    if (!this.schedule_type || !this.occurrences.length) {
      return true;
    }

    let grouped = {};
    if (this.schedule_type === ScheduleModel.ScheduleType.MonthlyOnDates) {
      grouped = _.groupBy(this.occurrences, obj => `${obj.day}`);
    } else if (this.schedule_type === ScheduleModel.ScheduleType.MonthlyDayOfWeek) {
      grouped = _.groupBy(this.occurrences, obj => `${obj.occurrence}-${obj.weekday}`);
    } else if (this.schedule_type === ScheduleModel.ScheduleType.YearlyOnDates) {
      grouped = _.groupBy(this.occurrences, obj => `${obj.month}-${obj.day}`);
    } else if (this.schedule_type === ScheduleModel.ScheduleType.YearlyDayOfWeek) {
      grouped = _.groupBy(this.occurrences, obj => `${obj.month}-${obj.weekday}-${obj.occurrence}`);
    }

    return !_.some(grouped, group => group.length > 1);
  }

  hasScheduleChanges(currentFields) {
    // test if any cached schedule field has changed
    if (this._detectChanges(this.cachedScheduleFields, currentFields)) return true;

    // also look in the guts of the "Repeat" data
    const occurrencesToTest = JSON.stringify(this.occurrences);
    const original = JSON.stringify(this.schedule_data || []);

    return occurrencesToTest !== original;
  }

  get scheduleBaseTypes() {
    return Object.keys(ScheduleModel.ScheduleTypeBase).map(type => ({
      text: type,
      value: ScheduleModel.ScheduleTypeBase[type],
    }));
  }

  get scheduleDescription() {
    const intervalDescription = scheduleIntervalToDescription(this.schedule_interval);
    let schedule = `Repeat ${intervalDescription}`;
    switch (this.schedule_type) {
      case ScheduleModel.ScheduleType.Daily: {
        schedule += ' day';
        break;
      }
      case ScheduleModel.ScheduleType.Weekly: {
        schedule += ' week';
        if (this.schedule_data.length > 0) {
          schedule += ' on ';
        }
        schedule += this.schedule_data
          .filter((val => 'weekday' in val))
          .map(val => scheduleWeekdayToDescription(val.weekday))
          .join(', ');
        break;
      }
      case ScheduleModel.ScheduleType.MonthlyOnDates: {
        schedule += ' month';
        if (this.schedule_data.length > 0) {
          schedule += ' on the ';
        }
        schedule += this.schedule_data
          .filter((val => 'day' in val))
          .map(val => nth(0, val.day))
          .join(', ');
        break;
      }
      case ScheduleModel.ScheduleType.MonthlyDayOfWeek: {
        schedule += ' month';
        if (this.schedule_data.length > 0) {
          schedule += ' on the ';
        }
        schedule += this.schedule_data
          .filter((val => 'weekday' in val && 'occurrence' in val))
          .map(val => `${nth(0, val.occurrence)} ${scheduleWeekdayToDescription(val.weekday)}`)
          .join(', ');
        break;
      }
      case ScheduleModel.ScheduleType.YearlyOnDates: {
        schedule += ' year';
        if (this.schedule_data.length > 0) {
          schedule += ' in ';
        }
        schedule += this.schedule_data
          .filter((val => 'month' in val && 'day' in val))
          .map(val => `${scheduleMonthToDescription(val.month)} on the ${nth(0, val.day)}`)
          .join(', ');
        break;
      }
      case ScheduleModel.ScheduleType.YearlyDayOfWeek: {
        schedule += ' year';
        if (this.schedule_data.length > 0) {
          schedule += ' on the ';
        }
        schedule += this.schedule_data
          .filter((val => 'month' in val && 'weekday' in val && 'occurrence' in val))
          .map(val => `${nth(0, val.occurrence)} ${scheduleWeekdayToDescription(val.weekday)} of ${scheduleMonthToDescription(val.month)}`)
          .join(', ');
        break;
      }
      default:
        break;
    }
    return schedule;
  }

  get scheduleTypeBaseStr() {
    return titleCase(this.schedule_type).split(' ')?.[0] || '';
  }

  get startDateObj() {
    return this.schedule_start_date ? new Date(this.schedule_start_date) : null;
  }

  get endDateObj() {
    return this.schedule_end_date ? new Date(this.schedule_end_date) : null;
  }
}
