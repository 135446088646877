import ProjectModel from './ProjectModel.js';
import { PropertyLocationModel } from './LocationModels';
import CompanyEquipmentModel from './CompanyEquipmentModel';
import PartModel from './PartModel';
import SensorDeviceModel from './SensorDeviceModel';
import TaskTemplateModel from './TaskTemplateModel';
import UserModel from './UserModel';
import { getTaskPriorityLabel } from '../constants/options.js';
import ScheduleModel from './ScheduleModel';

export default class PMScheduleModel extends ScheduleModel {
  init(data) {
    super.init(data);
    this.id = data.id || 0;
    this.task_template = data.task_template ? new TaskTemplateModel(data.task_template) : null;
    this.name = data.name || '';
    this.task_name_override = data.task_name_override || '';
    this.active = 'active' in data ? data.active : false;
    this.priority = data.priority || 0;
    this.project = data.project ? new ProjectModel(data.project) : null;
    this.location = (data.location || data.location_id) ? PropertyLocationModel.factory(data.location || { id: data.location_id }) : null;
    this.equipment = (data.equipment || data.equipment_id) ? new CompanyEquipmentModel(data.equipment || { id: data.equipment_id }) : null;
    this.part = (data.part || data.part_id) ? new PartModel(data.part || { id: data.part_id }) : null;
    this.sensor = (data.sensor || data.sensor_id) ? new SensorDeviceModel(data.sensor || { id: data.sensor_id }) : null;
    this.assignees = (data.assignees || []).map(a => new UserModel(a));

    this.created = data.created ? new Date(data.created) : null;
    this.updated = data.user_updated || data.updated ? new Date(data.user_updated || data.updated) : null;

    this.active_task_count = data.active_task_count || 0;

    this.cachedFields = {
      assignees: this.assignees.map(a => a.id),
      priority: this.priority,
      project: this.project?.id || null,
      location: this.location?.id || null,
      equipment: this.equipment?.id || null,
    };

    this.cachedScheduleFields = {
      schedule_type: this.schedule_type,
      schedule_interval: this.schedule_interval,
      schedule_start_date: this.schedule_start_date,
      schedule_end_date: this.schedule_end_date,
    };
  }

  _detectChanges(cacheObj, currentValues) {
    const fieldNames = Object.keys(cacheObj);
    let updatedFields = fieldNames.filter(field => (cacheObj[field] !== currentValues[field]));

    // special case for array comparison (this if will need updating if we ever track aother array)
    if (updatedFields.includes('assignees')) {
      if (cacheObj.assignees.length !== currentValues.assignees.length) return true;
      if (cacheObj.assignees.every((val, idx) => val !== currentValues.assignees[idx])) return true;

      updatedFields = updatedFields.filter(z => z !== 'assignees'); // assignees all good, remove
    }

    return !!updatedFields.length;
  }

  hasNonScheduleChanges(currentFields) {
    return this._detectChanges(this.cachedFields, currentFields);
  }

  get category() {
    return this.task_template?.category?.name || '';
  }

  get priorityLabel() {
    return getTaskPriorityLabel(this.priority);
  }

  get statusLabel() {
    return this.active ? 'Active' : 'Inactive';
  }

  get hasInactiveTemplate() {
    return (this.task_template?.archived ?? false) || (this.task_template?.deleted ?? false);
  }
}
