import BaseModel from './BaseModel';

export default class UserSummaryModel extends BaseModel {
  init(data) {
    this.role = data.role || '';
    this.seats = data.seats || 0;
    this.cost_per_seat = data.cost_per_seat || 0.0;
    this.sort_order = data.sort_order || 0;
  }

  get display_name() {
    const nameMap = new Map([
      ['admin', 'Admins'],
      ['manager', 'Managers'],
      ['work_order_requester', 'Requesters'],
      ['read_only', 'Read-Only'],
    ]);

    return nameMap.has(this.role) ? nameMap.get(this.role) : 'Standard & Custom';
  }
}
